/*Profile CSS*/
.profile-box{
    text-align: left;
}
.profile-box .profile-box-hd{
    width: 100%;
    height: 30px;
    line-height: 30px;
    border-left: 4px solid #1890ff;
    text-indent: 15px;
    font-size: 18px;
}
.profile-box .profile-box-bd{
    margin:15px 0 20px 0;
    text-align: justify;
    box-sizing: border-box;
}

.profile-item{
    width: 100%;
    box-sizing: border-box;
    padding:15px;
    display: block;
    margin: 15px 0;
    border: 1px solid #efefef;
}
.profile-item:hover{
    background: #FFFFFF;
    box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 10%);
    border-radius: 2px;
    border: 1px solid #fff;
    cursor: pointer;
}
.profile-item img{
    width: 46px;
    height: 46px;
    margin-right: 10px;
    float: left;
}
.profile-item h4{
    margin: 0;
    width: 100%;
    display: block;
}
.profile-item h4 span{
    color: #808080;
    float: right;
}
.profile-item .right{
    float: left;
    width: calc(100% - 56px);
    height: 46px;
}
.profile-item .duty{
    color: #808080;
    width: 100%;
    float: left;
    margin-top:5px;
}