
.contact-bg{
    width: 100%;
    height: 220px;
    background: #096dd9;
}
.contact-bg .img{
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.5;
    background-image: url('http://qiniu.marongrong.com/marongweb/i-contact-bg.jpg');
}
.contact-bd{
	min-height: auto;
	padding:24px 24px 1px;
}
.st-box {
    margin-top:-100px;
	text-align: center;
}
.st-box .st-bts a .circle {
	padding: 0 0 0 1px;
	display: block;
	width: 100%;
	height: 100%;
	border-radius: 100%;
	-moz-border-radius: 100%;
	-webkit-border-radius: 100%;
	-khtml-border-radius: 100%;
}

.st-box .st-bts a .icon {
	font-size: 16px;
	color: #304fff;
}

.st-box .st-image {
	margin: 0 auto 15px auto;
	width: 148px;
	height: 148px;
	overflow: hidden;
	position: relative;
	background: rgba(0, 0, 0, 0.2);
	border-radius: 148px;
	-moz-border-radius: 148px;
	-webkit-border-radius: 148px;
	-khtml-border-radius: 148px;
}

.st-box .st-image img {
	width: 100%;
	height: 100%;
	border-radius: 100%;
	-moz-border-radius: 100%;
	-webkit-border-radius: 100%;
	-khtml-border-radius: 100%;
}

.st-box .st-title {
	font-size: 42px;
	font-weight: 100;
	line-height: 44px;
	color: #363636;
	font-family: 'Roboto', helvetica;
}

.st-box .st-title strong {
	display: block;
	text-transform: uppercase;
	font-weight: 100;
}

.st-box .st-subtitle {
	margin: 15px 0 0 0;
	font-size: 16px;
	font-weight: 400;
	color: #363636;
	font-family: 'Roboto', helvetica;
}

.st-box .st-soc {
	margin: 25px 0;
}

.st-box .st-soc a {
	margin: 0 10px;
	position: relative;
	display: inline-block;
	vertical-align: top;
	overflow: hidden;
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	background: #1890ff;
	transition: all 0.2s ease 0s;
	-o-transition: all 0.2s ease 0s;
	border-radius: 52px;
	box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2);
    color: #fff;
    font-size: 25px;
    text-align: center;
}
.st-box .st-soc a:hover{
	background: #1874ff;
}

.st-box .st-soc a .circle {
	display: block;
	width: 100%;
	height: 100%;
	border-radius: 100%;
	-moz-border-radius: 100%;
	-webkit-border-radius: 100%;
	-khtml-border-radius: 100%;
}
.st-box .st-soc .icon {
	font-size: 21px;
	color: #fff;
}