.app-Header span{
    font-size: 12px;
    color: #808080;
}
.app-Header .logo{
    width: 270px;
    height: 60px;
    background-image: url('../assets/logo.png');
    float: left;
    margin-right: 60px;
}
.app-Content{
    width: 1300px;
    margin: 80px auto 0 auto;
    min-height: calc(100vh - 164px);
}
.content-wrap{
    background-color: #fff;
    padding: 24px; 
    min-height: 380px;
}