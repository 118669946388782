
.pro-list{

}
.pro-list .list-item{
    position: relative;
    padding-bottom: 20px;    
}
.pro-list .list-item .list-item-img{
    cursor: pointer;
    width: 298px;
    height: 248px;
    overflow: hidden;
}
.pro-list .list-item .list-item-img:hover:before{
    opacity: .8;
}
.pro-list .list-item .list-item-img:before{
    content: " ";
    background: #1e2025;
    width: 100%;
    height: 248px;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transition: opacity .5s;
    transition: opacity .5s;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 0;
    display: block;
}
.pro-list .list-item .list-item-img img{
    width: 100%;
}
.pro-list .list-item .ant-divider-horizontal{
    margin:5 0px;
}
.pro-list .list-item .list-item-title{
    font-size: 16px;
    font-weight: bold;
    color: #666;
    text-align: left;
    line-height: 30px;
}
.pro-list .list-item .list-item-date{
    font-size: 12px;
    color: #808080; 
    text-align: left;
}
.pro-detail-content img{
    max-width: 100%;
}